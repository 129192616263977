import React from 'react'
import { array, bool, string, func, any } from 'prop-types'
// Internal
import { Input } from './Input'

export function Text(props) {
  return <Input type="text" {...props} />
}

Text.displayName = 'TextInput'

Text.propTypes = {
  id: string,
  inputMode: string,
  required: bool,
  label: string.isRequired,
  placeholder: string,
  className: string,
  errorClassName: string,
  labelClassName: string,
  rowClassName: string,
  name: string.isRequired,
  errors: array.isRequired,
  value: any.isRequired,
  autoComplete: string,
  autoFocus: bool,
  onChange: func.isRequired,
}
