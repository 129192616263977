import React, { useContext } from 'react'
import { createOnSubmit, useFormChange } from 'react-endpoint'
import { api } from '@whirlwindjs/client'
// Internal
import { Context } from '../../context'
import { Email, ErrorMessage, Password, Submit, Text } from '../../elements'
import { Page } from '../../layouts'

export function Register() {
  const { helpers } = useContext(Context)
  const initialState = {
    name: '',
    email: '',
    emailConfirmation: '',
    password: '',
    passwordConfirmation: '',
    errors: [],
    isLoading: false,
    message: '',
  }
  const [onChange, values, setValues] = useFormChange(initialState)
  const makeRequest = async (inputs) => api.auth.register(inputs)
  const onSuccess = ({ user }) => {
    if (!user) {
      return setValues({
        ...values,
        errors: {
          message: 'An unexpected error has occurred. Please try again.',
        },
      })
    }
    setValues(initialState)
    helpers.setUser(user)
    helpers.flash('Successfully registered, welcome.')
  }
  const onSubmit = createOnSubmit({
    useState: [values, setValues],
    onSuccess,
    makeRequest,
  })
  return (
    <Page>
      <form onSubmit={onSubmit}>
        <h1>Register</h1>
        {values.isLoading && 'Loading.... Please wait'}
        <ErrorMessage message={values.message} />
        <Text
          name="name"
          value={values.name}
          label="Name"
          placeholder="John Doe"
          autoFocus={true}
          autoComplete="name"
          errors={values.errors}
          onChange={onChange}
        />
        <Email value={values.email} onChange={onChange} errors={values.errors} />
        <Email
          id="email-confirm"
          label="Confirm Email"
          name="emailConfirmation"
          value={values.emailConfirmation}
          onChange={onChange}
          errors={values.errors}
        />
        <Password
          autoComplete="new-password"
          value={values.password}
          onChange={onChange}
          errors={values.errors}
        />
        <Password
          id="password-confirm"
          label="Confirm Password"
          name="passwordConfirmation"
          autoComplete="new-password"
          value={values.passwordConfirmation}
          onChange={onChange}
          errors={values.errors}
        />
        <Submit value="Register" disabled={values.isLoading} />
      </form>
    </Page>
  )
}

Register.displayName = 'RegisterPage'
