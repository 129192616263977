import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { bool, elementType, string } from 'prop-types'
// Internal
import { Context } from '../context'

export function GuestRoute({ component: Component, path, redirectTo, ...rest }) {
  const { state } = useContext(Context)
  if (state.user) {
    return (
      <Route {...rest} path={path}>
        {/* TODO: See if this can be placed in the render below */}
        <Redirect to={redirectTo} />
      </Route>
    )
  }
  return <Route {...rest} path={path} render={(props) => <Component {...props} />} />
}

GuestRoute.displayName = 'GuestRoute'

GuestRoute.defaultProps = {
  exact: true,
  redirectTo: 'dashboard',
}

GuestRoute.propTypes = {
  component: elementType.isRequired,
  path: string.isRequired,
  exact: bool,
  redirectTo: string,
}
