import React, { useContext } from 'react'
import { string } from 'prop-types'
// Internal
import { Context } from '../../context'
import { Message } from './Message'

export function FlashMessage(props) {
  const { state } = useContext(Context)
  return <Message className={props.className} message={state.flashMessage} />
}

FlashMessage.displayName = 'FlashMessage'

FlashMessage.propTypes = {
  className: string,
}
