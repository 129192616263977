import React, { useContext } from 'react'
// Internal
import { FlashMessage } from '../elements'
import { Page } from '../layouts'
import { Context } from '../context'

export function Welcome() {
  const { state } = useContext(Context)
  return (
    <Page>
      <h1>Welcome</h1>
      <FlashMessage />
      <p>
        {!state.user
          ? 'Register or Login to view the dashboard.'
          : 'Edit your account by visiting Dashboard -> Your Account -> Edit.'}
      </p>
    </Page>
  )
}

Welcome.displayName = 'WelcomePage'
