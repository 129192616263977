import React from 'react'
import { string } from 'prop-types'
// Internal
import { Message } from './Message'

export function ErrorMessage(props) {
  return <Message {...props} />
}

ErrorMessage.displayName = 'ErrorMessage'

ErrorMessage.propTypes = {
  className: string,
  message: string,
}
