import React from 'react'
// Internal
import { Page } from '../layouts'

export function NotFound() {
  return (
    <Page>
      <h1>404 - Not Found!</h1>
    </Page>
  )
}

NotFound.displayName = 'NotFound'
