import React, { useContext } from 'react'
import { createOnSubmit, useFormChange } from 'react-endpoint'
import { api } from '@whirlwindjs/client'
// Internal
import { Context } from '../../../context'
import { Email, ErrorMessage, FlashMessage, GreenCheckmark, PageLink, Submit } from '../../../elements'
import { useQuery } from '../../../hooks'
import { Page } from '../../../layouts'

export function EmailResetLink() {
  const [query] = useQuery()
  const initialState = {
    errors: [],
    message: '',
    isLoading: false,
    email: query.email || '',
    sent: false,
  }
  const { state, helpers } = useContext(Context)
  const [onChange, values, setValues] = useFormChange(initialState)
  const onSubmit = createOnSubmit({
    makeRequest: async ({ email }) => api.password.requestPasswordReset(email),
    onSuccess: () => {
      setValues({ ...initialState, email: '', sent: true })
      helpers.flash('We have emailed your password reset link!')
    },
    useState: [values, setValues],
  })
  if (values.sent) {
    return (
      <Page>
        <h1>Forgot Password</h1>
        {state.user && (
          <p>
            <PageLink to="/user" title="Back to Your Account" />
          </p>
        )}
        <p>
          <GreenCheckmark prefix="We have emailed your password reset link!" />
        </p>
      </Page>
    )
  }
  return (
    <Page>
      <form onSubmit={onSubmit}>
        <h1>Forgot Password</h1>
        {state.user && (
          <p>
            <PageLink to="/user" title="Back to Your Account" />
          </p>
        )}
        {values.isLoading && 'Loading.... Please wait'}
        <FlashMessage />
        <ErrorMessage message={values.message} />
        <Email autoFocus={true} value={values.email} onChange={onChange} errors={values.errors} />
        <Submit value="Send Password Reset Link" disabled={values.isLoading} />
      </form>
    </Page>
  )
}

EmailResetLink.displayName = 'EmailResetLinkPage'
