import React, { useContext } from 'react'
import { createOnSubmit, useFormChange } from 'react-endpoint'
import { api } from '@whirlwindjs/client'
// Internal
import { Context } from '../context'
import { GreenCheckmark, HiddenCheckbox, Email, ErrorMessage, Submit, Text, Textarea } from '../elements'
import { Page } from '../layouts'

export function ContactUs() {
  const { state } = useContext(Context)
  const initialState = {
    email: '',
    name: '',
    contactMessage: '',
    contactByFaxOnly: false,
    sent: false,
    errors: [],
    isLoading: false,
    message: '',
  }
  const [onChange, values, setValues] = useFormChange(initialState)
  const onSubmit = createOnSubmit({
    makeRequest: async ({ contactMessage: message, ...inputs }) =>
      api.contact.contactUs({ ...inputs, message }),
    onSuccess: async () => {
      setValues({ ...initialState, sent: true })
    },
    useState: [values, setValues],
  })
  if (values.sent) {
    return (
      <Page>
        <h1>Contact</h1>
        <p>
          <GreenCheckmark prefix="Your message has been sent" />
        </p>
      </Page>
    )
  }
  return (
    <Page>
      <form onSubmit={onSubmit}>
        <h1>Contact</h1>
        {values.isLoading && 'Loading.... Please wait'}
        <ErrorMessage message={values.message} />
        {!state.user && (
          <Text
            name="name"
            placeholder="Your Name"
            value={values.name}
            autoFocus={true}
            onChange={onChange}
            errors={values.errors}
            label="Name"
          />
        )}
        {!state.user && <Email value={values.email} onChange={onChange} errors={values.errors} />}
        {/* NOTE: This input is meant to try to help fight against spam bots, they may fill this in even though it's hidden */}
        <HiddenCheckbox onChange={onChange} checked={values.contactByFaxOnly} name="contactByFaxOnly" />
        <Textarea
          name="contactMessage"
          value={values.contactMessage}
          onChange={onChange}
          errors={values.errors}
          label="Message"
        />
        <Submit value="Send Message" disabled={values.isLoading} />
      </form>
    </Page>
  )
}

ContactUs.displayName = 'ContactUs'
