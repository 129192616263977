import React from 'react'
import { node, string } from 'prop-types'
import styled from 'styled-components'

const SiteHeader = styled.header`
  grid-area: header;
`

export function Header(props) {
  return <SiteHeader className={props.className}>{props.children}</SiteHeader>
}

Header.displayName = 'SiteHeader'

Header.propTypes = {
  className: string,
  children: node,
}
