import React, { useContext, useEffect } from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components'
import { node, string } from 'prop-types'
// Internal
import { Context } from '../context'

const SiteDiv = styled.div`
  display: grid;
  width: 100%;
  max-width: 990px;
  margin: 0 auto;
  padding: 0 30px;
  background-color: white;

  grid-template-areas:
    'header header'
    'container container'
    'footer footer';
  grid-template-rows: 150px 1fr 100px;
  grid-template-columns: 1fr 200px;

  a {
    color: #2b6cb0;
    color: rgba(43, 108, 176, 1);
  }

  a:visited {
    color: #2b6cb0;
    color: rgba(43, 108, 176, 1);
  }
  a:hover {
    color: black;
    text-decoration: underline;
  }
`

export function Site(props) {
  const { helpers, state } = useContext(Context)
  useEffect(() => {
    if (!state.initialized) {
      helpers.initializeUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.initialized])
  return <SiteDiv>{!state.initialized ? <ReactLoading type="bars" color="black" /> : props.children}</SiteDiv>
}

Site.displayName = 'Site'

Site.propTypes = {
  className: string,
  children: node.isRequired,
}
