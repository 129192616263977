import React from 'react'
import { Route } from 'react-router-dom'
import { bool, elementType, string } from 'prop-types'

export function AppRoute({ component: Component, path, ...rest }) {
  return <Route {...rest} path={path || '/'} render={(props) => <Component {...props} />} />
}

AppRoute.displayName = 'AppRoute'

AppRoute.defaultProps = {
  exact: true,
  path: '',
}

AppRoute.propTypes = {
  component: elementType.isRequired,
  exact: bool,
  path: string,
}
