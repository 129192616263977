import styled from 'styled-components'

export const Page = styled.article`
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  h1 {
    font-size: 2rem;
    margin: 0 0 26px;
  }
`
