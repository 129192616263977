import React, { useContext, useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { api } from '@whirlwindjs/client'
import { safeResponseData } from 'resource-endpoint'
import { useHistory, useParams } from 'react-router'
// Internal
import { actions, Context } from '../../context'
import { DisplayErrors, ErrorMessage } from '../../elements'
import { Page } from '../../layouts'

export function VerifyConfirm() {
  const history = useHistory()
  const { helpers } = useContext(Context)
  const { token } = useParams()
  const [values, setValues] = useState({
    errors: [],
    loading: true,
    called: false,
    message: '',
  })
  useEffect(() => {
    if (values.loading && !values.called) {
      setValues({
        ...values,
        called: true,
      })
      api.user.verifyEmail(token).then((response) => {
        const { errors, message, user } = safeResponseData(response)
        if (errors || message) {
          setValues({
            ...values,
            loading: false,
            errors,
            message,
          })
          return
        }
        setValues({
          ...values,
          errors: [],
          message: '',
        })
        helpers.flash('Successfully verified email.')
        // Login whatever user just verified their email.
        helpers.setUser(user)
        helpers.dispatchAction(actions.SHOW_AUTHENTICATE_MODAL, false)
        history.push('/user')
      })
    }
    // eslint-disable-next-line
  }, [values.called, values.errors, values.loading, values.message])
  return (
    <Page>
      <h1>Verifying Your Email Address</h1>
      {values.loading && <ReactLoading type="bars" color="black" />}
      {values.message && (
        <p>
          An error occurred while attempting to verify your email:
          <ErrorMessage message={values.message} />
        </p>
      )}
      <DisplayErrors name="token" errors={values.errors} />
    </Page>
  )
}

VerifyConfirm.displayName = 'VerifyConfirmPage'
