import React from 'react'
import { Link } from 'react-router-dom'
import { string } from 'prop-types'

export function PageLink(props) {
  return (
    <Link className={props.className} to={props.to}>
      {props.title}
    </Link>
  )
}

PageLink.displayName = 'PageLink'

PageLink.propTypes = {
  className: string,
  to: string.isRequired,
  title: string.isRequired,
}
