import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { string } from 'prop-types'
import { api } from '@whirlwindjs/client'
// Internal
import { Context } from '../context'

export function LogoutLink(props) {
  const history = useHistory()
  const { helpers } = useContext(Context)
  const onLogout = async (e) => {
    e.preventDefault()
    const response = await api.auth.logout()
    const { errors, message } = response.data
    if (errors) {
      // TODO: Add modal showing error message, give option to refresh page to see if that fixes issue.
      // update({ type: 'errors', errors })
      return
    } else if (message) {
      // TODO: Add modal showing error message, give option to refresh page to see if that fixes issue.
      // update({ type: 'errors', errors })
      helpers.flash(message)
      return
    }
    helpers.setUser(null)
    helpers.flash('Successfully logged out.')
    history.push('/')
  }
  return (
    <a onClick={onLogout} href="/logout" className={props.className}>
      Logout
    </a>
  )
}

LogoutLink.displayName = 'LogoutLink'

LogoutLink.propTypes = {
  className: string,
}
