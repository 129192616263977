import React from 'react'
import { array, bool, string, func, any } from 'prop-types'
// Internal
import { Input } from './Input'

export function Email(props) {
  return <Input type="email" autoComplete="email" inputMode="email" {...props} />
}

Email.displayName = 'EmailInput'

Email.defaultProps = {
  label: 'Email Address',
  name: 'email',
  placeholder: 'john.doe@example.com',
}

Email.propTypes = {
  label: string,
  id: string,
  placeholder: string,
  className: string,
  errorClassName: string,
  labelClassName: string,
  rowClassName: string,
  name: string,
  required: bool,
  errors: array.isRequired,
  value: any.isRequired,
  autoFocus: bool,
  onChange: func.isRequired,
}
