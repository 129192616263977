import { useLocation } from 'react-router-dom'
import qs from 'qs'

export function useQuery(parseOptions) {
  const location = useLocation()
  const search = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    ...parseOptions,
  })
  const query = (search && typeof search === 'object' && search) || {}
  return [query, location]
}
