import React from 'react'
import { array, bool, func, string } from 'prop-types'
// Internal
import { ListChildren } from './ListChildren'

export function List(props) {
  const { isOrdered, className, itemClassName, ...childProps } = props
  return isOrdered ? (
    <ol className={className}>
      <ListChildren className={itemClassName} {...childProps} />
    </ol>
  ) : (
    <ul className={className}>
      <ListChildren className={itemClassName} {...childProps} />
    </ul>
  )
}

List.defaultProps = {
  isOrdered: false,
}

List.propTypes = {
  items: array.isRequired,
  render: func.isRequired,
  className: string,
  isOrdered: bool,
  itemClassName: string,
}
