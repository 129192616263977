import styled from 'styled-components'
import React, { Fragment } from 'react'
import { string } from 'prop-types'

export const GreenColorIcon = styled.i`
  color: green;
`

export function GreenCheckmark(props) {
  return (
    <Fragment>
      {props.prefix && <span>{props.prefix} </span>}
      <GreenColorIcon>✔</GreenColorIcon>
      {props.suffix && <span> {props.suffix}</span>}
    </Fragment>
  )
}

GreenCheckmark.propTypes = {
  prefix: string,
  suffix: string,
}
