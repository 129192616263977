import React from 'react'
import { array, string } from 'prop-types'
// Internal
import { ErrorMessage } from './index'

export function getErrors(props) {
  if (!Array.isArray(props.errors)) {
    return []
  }
  const errors = props.errors.filter((value) => value.param === props.name)
  if (errors.length === 0) {
    return []
  }
  return errors
}

export function DisplayErrors(props) {
  const errors = getErrors(props)
  if (errors.length === 0) {
    return ''
  }
  return errors.map((validation, key) => (
    <ErrorMessage key={key} className={props.className} message={validation.msg} />
  ))
}

DisplayErrors.displayName = 'DisplayErrorMessage'

DisplayErrors.propTypes = {
  className: string,
  name: string.isRequired,
  errors: array.isRequired,
}
