import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import { bool, elementType, string } from 'prop-types'
// Internal
import { Context } from '../context'
import { Login } from '../pages'

export function AuthRoute({ component: Component, ...rest }) {
  const { state } = useContext(Context)
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!state.user) {
          return <Login {...props} />
        }
        return <Component {...props} />
      }}
    />
  )
}

AuthRoute.displayName = 'AuthRoute'

AuthRoute.defaultProps = {
  exact: true,
}

AuthRoute.propTypes = {
  component: elementType.isRequired,
  path: string.isRequired,
  exact: bool,
}
