import React, { useState } from 'react'
import { safeResponseData } from 'resource-endpoint'
import { api } from '@whirlwindjs/client'
// Internal
import { ErrorMessage } from '../../elements'
import { Page } from '../../layouts'

export function VerifyNotice() {
  const initialState = {
    errors: [],
    message: '',
    resent: false,
  }
  const [values, setValues] = useState(initialState)
  const onVerifyRequest = async (e) => {
    e.preventDefault()
    const { errors, message } = safeResponseData(await api.user.resendEmailVerification())
    // Likely will only be message for this API call.
    if (errors || message) {
      setValues({
        ...values,
        errors,
        message,
      })
      return
    }
    setValues({
      ...initialState,
      resent: true,
    })
  }
  return (
    <Page>
      <h1>Verify Your Email Address</h1>
      {values.message && (
        <p>
          An error occurred while requesting a new verification link:
          <ErrorMessage message={values.message} />
        </p>
      )}
      {/* TODO: Maybe have a timestamp on when the link was sent. */}
      {values.resent && <p>A fresh verification link has been sent to your email address.</p>}
      <p>Before proceeding, please check your email for a verification link.</p>
      <p>If you did not receive the email</p>
      <a onClick={onVerifyRequest} href="#">
        click here to request another
      </a>
      .
    </Page>
  )
}

VerifyNotice.displayName = 'VerifyNoticePage'
