import React from 'react'
// Internal
import { FlashMessage, List, PageLink } from '../elements'
import { Page } from '../layouts'

export function Dashboard() {
  return (
    <Page>
      <h1>Dashboard</h1>
      <FlashMessage />
      <List
        items={[
          {
            to: '/user',
            title: 'Your Account',
          },
        ]}
        render={(link) => <PageLink to={link.to} title={link.title} />}
      />
    </Page>
  )
}

Dashboard.displayName = 'Dashboard'
