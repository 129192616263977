import React, { useContext } from 'react'
import { createOnSubmit, useFormChange } from 'react-endpoint'
import { useHistory } from 'react-router'
import { api } from '@whirlwindjs/client'
// Internal
import { Context } from '../../context'
import { Email, Submit, Text, PageLink, ErrorMessage } from '../../elements'
import { Page } from '../../layouts'

export function UserEdit() {
  const history = useHistory()
  const { state, helpers } = useContext(Context)
  const initialState = {
    errors: [],
    message: '',
    email: state.user.email,
    name: state.user.name,
  }
  const [onChange, values, setValues] = useFormChange(initialState)
  const onSubmit = createOnSubmit({
    makeRequest: async (inputs) => api.user.updateCurrent(inputs),
    onSuccess: ({ errors, message, user }) => {
      if (errors || message) {
        setValues({
          ...values,
          errors,
          message,
        })
        return
      }
      setValues(initialState)
      helpers.setUser(user)
      helpers.flash('Successfully updated information.')
      history.push('/user')
    },
    useState: [values, setValues],
  })
  return (
    <Page>
      <form onSubmit={onSubmit}>
        <h1>Update Information</h1>
        <ErrorMessage message={values.message} />
        <p>
          <PageLink to="/user" title="Back to Your Account" />
        </p>
        <Email
          autoFocus={true}
          required={true}
          errors={values.errors}
          onChange={onChange}
          value={values.email}
        />
        <Text
          label="Name"
          name="name"
          required={true}
          errors={values.errors}
          onChange={onChange}
          value={values.name}
        />
        <Submit value="Update" />
      </form>
    </Page>
  )
}

UserEdit.displayName = 'EditUser'
