import React from 'react'
import { node, string } from 'prop-types'
import styled from 'styled-components'

const Main = styled.main`
  grid-area: container;
`

export function Container(props) {
  return <Main className={props.className}>{props.children}</Main>
}

Container.displayName = 'AppContainer'

Container.propTypes = {
  className: string,
  children: node.isRequired,
}
