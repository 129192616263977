import React from 'react'
import { array, func, string } from 'prop-types'

export function ListChildren(props) {
  return props.items.map((item, key) => (
    <li className={props.className} key={key}>
      {props.render(item, key)}
    </li>
  ))
}

ListChildren.displayName = 'ListChildren'

ListChildren.propTypes = {
  items: array.isRequired,
  render: func.isRequired,
  className: string,
}
