import React from 'react'
import { array, bool, string, func, any } from 'prop-types'
import styled from 'styled-components'
// Internal
import { DisplayErrors, getErrors } from '../DisplayErrors'

export const InputGroup = styled.div`
  margin-bottom: 1rem;
`

export const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`

export function Input(props) {
  const errors = getErrors(props)
  let className = props.className
  if (errors.length !== 0 && props.errorClassName) {
    className = typeof className === 'string' ? `${className} ${props.errorClassName}` : props.errorClassName
  }
  return (
    <InputGroup className={props.rowClassName}>
      <Label className={props.labelClassName} htmlFor={props.id || props.name}>
        {props.label}
      </Label>
      <input
        id={props.id || props.name}
        name={props.name}
        type={props.type}
        disabled={props.disabled}
        className={className}
        required={props.required}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        inputMode={props.inputMode}
        autoFocus={props.autoFocus}
        value={props.value}
        onChange={props.onChange}
      />
      <DisplayErrors name={props.name} errors={props.errors} />
    </InputGroup>
  )
}

Input.displayName = 'Input'

Input.defaultProps = {
  autoFocus: false,
  disabled: false,
  required: true,
}

Input.propTypes = {
  id: string,
  type: string.isRequired,
  inputMode: string,
  required: bool,
  disabled: bool,
  label: string.isRequired,
  placeholder: string,
  className: string,
  labelClassName: string,
  errorClassName: string,
  rowClassName: string,
  name: string.isRequired,
  errors: array.isRequired,
  value: any.isRequired,
  autoComplete: string,
  autoFocus: bool,
  onChange: func.isRequired,
}
