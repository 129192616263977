import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
// Internal
import './App.css'
import { Container, Footer, Header, Site } from './layouts'
import { UserNavigation } from './components'
import { AppRoute, AuthRoute, GuestRoute } from './routes'
import {
  ContactUs,
  Dashboard,
  EmailResetLink,
  Login,
  NotFound,
  Register,
  ResetPassword,
  User,
  UserEdit,
  VerifyConfirm,
  VerifyNotice,
  Welcome,
} from './pages'
import { PageNavLink } from './elements'
import { fetchCsrfCookie } from './helpers'

const SiteTitle = styled.h1`
  a {
    color: black;
    text-decoration: none;
  }
  a:visited {
    color: black;
  }
  a:hover {
    text-decoration: none;
  }
  font-size: 2.4rem;
`

export function App() {
  useEffect(() => {
    fetchCsrfCookie()
  })
  return (
    <Site>
      <Router>
        <Header>
          <SiteTitle>
            <PageNavLink activeClassName="" title="Whirlwindjs App" to="/" />
          </SiteTitle>
          <UserNavigation />
        </Header>
        <Container>
          <Switch>
            {/* Basic Root Pages */}
            <AppRoute path="/" component={Welcome} />
            <AuthRoute path="/dashboard" component={Dashboard} />
            {/* Contact Us */}
            <AppRoute path="/contact" component={ContactUs} />
            {/* Login/Register */}
            <GuestRoute path="/login" component={Login} />
            <GuestRoute path="/register" component={Register} />
            {/* Email Verification Confirm/Notice */}
            <AppRoute path="/email/verify/:token" component={VerifyConfirm} />
            <AuthRoute path="/email/verify" component={VerifyNotice} />
            {/* Forgot/Reset Password */}
            <AuthRoute path="/password/change" component={ResetPassword} />
            <AppRoute path="/password/forgot" component={EmailResetLink} />
            <AppRoute path="/password/reset/:token" component={ResetPassword} />
            {/* User Account & Edit */}
            <AuthRoute path="/user/edit" component={UserEdit} />
            <AuthRoute path="/user" component={User} />
            <Route component={NotFound} />
          </Switch>
        </Container>
        <Footer>Whirlwindjs App © 2019 – {new Date().getFullYear()}</Footer>
      </Router>
    </Site>
  )
}
