import React from 'react'
import { array, bool, string, func } from 'prop-types'
import styled from 'styled-components'
// Internal
import { DisplayErrors } from '../DisplayErrors'
import { InputGroup, Label } from './Input'

export const DisplayNoneInput = styled.input`
  display: none;
`

export function HiddenCheckbox(props) {
  return (
    <DisplayNoneInput
      type="checkbox"
      name={props.name}
      className={props.className}
      checked={props.checked}
      id={props.id || props.name}
      onChange={props.onChange}
    />
  )
}

HiddenCheckbox.displayName = 'HiddenCheckboxInput'

HiddenCheckbox.propTypes = {
  checked: bool.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  className: string,
  id: string,
}

export function Checkbox(props) {
  return (
    <InputGroup className={props.rowClassName}>
      <Label className={props.labelClassName} htmlFor={props.id || props.name}>
        {props.label}
      </Label>
      <input
        type="checkbox"
        name={props.name}
        className={props.className}
        checked={props.checked}
        required={props.required}
        id={props.id || props.name}
        onChange={props.onChange}
      />
      <DisplayErrors name={props.name} errors={props.errors} />
    </InputGroup>
  )
}

Checkbox.displayName = 'CheckboxInput'

Checkbox.defaultProps = {
  required: false,
}

Checkbox.propTypes = {
  checked: bool.isRequired,
  errors: array.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  labelClassName: string,
  className: string,
  id: string,
  required: bool,
  rowClassName: string,
}
