import React from 'react'
import { node, string } from 'prop-types'
import styled from 'styled-components'

const SiteFooter = styled.footer`
  grid-area: footer;
  margin-top: 46px;
`

export function Footer(props) {
  return <SiteFooter className={props.className}>{props.children}</SiteFooter>
}

Footer.displayName = 'SiteFooter'

Footer.propTypes = {
  className: string,
  children: node,
}
