import React, { Fragment, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { createOnSubmit, useFormChange } from 'react-endpoint'
import { stringify } from 'qs'
import { api } from '@whirlwindjs/client'
// Internal
import { Context } from '../../../context'
import { DisplayErrors, ErrorMessage, PageLink, Password, Submit } from '../../../elements'
import { Page } from '../../../layouts'

export function ResetPassword() {
  const { token } = useParams()
  const history = useHistory()
  const { state, helpers } = useContext(Context)
  const initialState = {
    errors: [],
    message: '',
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
    token,
    isLoading: false,
  }
  const isChangePassword = !token && state.user
  const [onChange, values, setValues] = useFormChange(initialState)
  const onSubmit = createOnSubmit({
    onSuccess: ({ user }) => {
      setValues(initialState)
      if (!state.user && user) {
        helpers.setUser(user)
      }
      helpers.flash('Your password has been reset!')
      history.push('/dashboard')
    },
    makeRequest: async (inputs) =>
      isChangePassword ? api.user.changePassword(inputs) : api.password.resetPassword(inputs),
    useState: [values, setValues],
  })
  return (
    <Page>
      <form onSubmit={onSubmit}>
        <h1>{isChangePassword ? 'Change' : 'Reset'} Password</h1>
        {values.isLoading && 'Loading.... Please wait'}
        <ErrorMessage message={values.message} />
        <DisplayErrors name="token" errors={values.errors} />
        {isChangePassword && (
          <Fragment>
            <p>
              <PageLink to="/user" title="Back to Your Account" />
            </p>
            <Password
              id="current-password"
              label="Current Password"
              name="currentPassword"
              value={values.currentPassword}
              onChange={onChange}
              errors={values.errors}
            />
          </Fragment>
        )}
        <Password
          autoComplete="new-password"
          value={values.password}
          onChange={onChange}
          errors={values.errors}
        />
        <Password
          id="password-confirm"
          label="Confirm Password"
          name="passwordConfirmation"
          autoComplete="new-password"
          value={values.passwordConfirmation}
          onChange={onChange}
          errors={values.errors}
        />
        <Submit value={`${isChangePassword ? 'Change' : 'Reset'} Password`} disabled={values.isLoading}>
          <p>
            <PageLink
              to={`/password/forgot${state.user && `?${stringify({ email: state.user.email })}`}`}
              title="Forgot Your Password?"
            />
          </p>
        </Submit>
      </form>
    </Page>
  )
}

ResetPassword.displayName = 'ResetPasswordPage'
