import React from 'react'
import { NavLink } from 'react-router-dom'
import { string } from 'prop-types'

export function PageNavLink(props) {
  return (
    <NavLink exact={true} className={props.className} activeClassName={props.activeClassName} to={props.to}>
      {props.title}
    </NavLink>
  )
}

PageNavLink.displayName = 'PageNavLink'

PageNavLink.defaultProps = {
  activeClassName: 'underline',
}

PageNavLink.propTypes = {
  className: string,
  activeClassName: string,
  to: string.isRequired,
  title: string.isRequired,
}
