import React, { useContext } from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
// Internal
import { Context } from '../../context'
import { ListChildren, LogoutLink, PageNavLink } from '../../elements'

const Nav = styled.nav`
  a {
    text-decoration: none;
  }
  a.active-page {
    color: black;
    text-decoration: underline;
  }
  ul:last-child {
    padding-bottom: 10px;
    border-bottom: 1px black solid;
  }
`
const NavList = styled.ul`
  display: block;
  list-style: none;
  padding-left: 0;
  padding-right: 0;
  margin: 12px 0;
  li {
    display: inline-block;
    vertical-align: middle;
    padding-right: 15px;
  }
  li:first-child {
    padding-left: 0;
  }
  li.logout {
    float: right !important;
    padding-right: 0;
  }
  li.logout .greeting {
    padding-right: 0.5rem;
  }
`

export function UserNavigation(props) {
  const { state } = useContext(Context)
  if (!state.user) {
    return (
      <Nav>
        <NavList>
          <ListChildren
            className={props.className}
            items={[
              {
                to: '/',
                title: 'Home',
              },
              {
                to: '/login',
                title: 'Login',
              },
              {
                to: '/register',
                title: 'Register',
              },
              {
                to: '/contact',
                title: 'Contact',
              },
            ]}
            render={(link) => (
              <PageNavLink
                activeClassName={props.activeClassName}
                className={props.linkClassName}
                to={link.to}
                title={link.title}
              />
            )}
          />
        </NavList>
      </Nav>
    )
  }
  return (
    <Nav>
      <NavList>
        <ListChildren
          className={props.className}
          items={[
            {
              to: '/',
              title: 'Home',
            },
            {
              to: '/dashboard',
              title: 'Dashboard',
            },
            {
              to: '/contact',
              title: 'Contact',
            },
          ]}
          render={(link) => (
            <PageNavLink
              activeClassName={props.activeClassName}
              className={props.linkClassName}
              to={link.to}
              title={link.title}
            />
          )}
        />
        <li className="logout">
          <span className="greeting">Hello, {state.user.name}.</span>{' '}
          <LogoutLink className={props.linkClassName} />
        </li>
      </NavList>
    </Nav>
  )
}

UserNavigation.displayName = 'UserNavigation'

UserNavigation.defaultProps = {
  activeClassName: 'active-page',
}

UserNavigation.propTypes = {
  activeClassName: string,
  className: string,
  linkClassName: string,
}
