import React, { Fragment, useContext, useState } from 'react'
import { DateTime } from 'luxon'
import { safeResponseData } from 'resource-endpoint'
import { api } from '@whirlwindjs/client'
// Internal
import { Context } from '../../context'
import { FlashMessage, GreenCheckmark, PageLink } from '../../elements'
import { Page } from '../../layouts'

export function User() {
  const { state } = useContext(Context)
  const initialState = {
    errors: {},
    message: '',
    sent: false,
  }
  const [values, setValues] = useState(initialState)
  const onVerifyRequest = async (e) => {
    e.preventDefault()
    const { errors, message } = safeResponseData(await api.user.resendEmailVerification())
    // Likely will only be message for this API call.
    if (errors || message) {
      setValues({
        ...values,
        errors,
        message,
      })
      return
    }
    setValues({
      ...initialState,
      sent: true,
    })
    // NOTE: Could also just flash a message?
  }
  return (
    <Page>
      <h1>Account</h1>
      <FlashMessage />
      <section>
        <h2>Security</h2>
        <ul>
          <li>
            <PageLink to="/password/change" title="Change Password" />
          </li>
        </ul>
      </section>
      <section>
        <h2>Details</h2>
        <PageLink to="/user/edit" title="Edit" />
        <ul>
          <li>
            <strong>ID:</strong> {state.user.id}
          </li>
          <li>
            <strong>Name:</strong> {state.user.name}
          </li>
          <li>
            <strong>Email:</strong> {state.user.email}
          </li>
          <li>
            <strong>Email Verification:</strong> {state.user.emailVerifiedAt ? 'Verified' : 'Unverified'}
            {!state.user.emailVerifiedAt && values.sent && <GreenCheckmark prefix=" - Sent email" />}
            {!state.user.emailVerifiedAt && !values.sent && (
              <Fragment>
                {' - '}
                <a onClick={onVerifyRequest} href="#">
                  Verify Email
                </a>
              </Fragment>
            )}
          </li>
          <li>
            <strong>Last Updated:</strong>{' '}
            {DateTime.fromISO(state.user.updatedAt).toUTC().toFormat("EEE, dd LLL yyyy hh:mm:ss a 'GMT'")}
          </li>
        </ul>
      </section>
    </Page>
  )
}

User.displayName = 'UserPage'
