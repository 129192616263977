import React from 'react'
import { string } from 'prop-types'

export function Message(props) {
  if (!props.message) {
    return ''
  }
  return <p className={props.className}>{props.message}</p>
}

Message.displayName = 'Message'

Message.defaultProps = {
  className: '',
}

Message.propTypes = {
  className: string,
  message: string,
}
