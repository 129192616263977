import { api } from '@whirlwindjs/client'

export async function fetchCsrfCookie() {
  const response = await api.misc.csrfCookie()
  const success = response.status === 204
  if (!success) {
    // Log error to some service, bugsnag or otherwise.
    window.console.error('error when calling csrfCookie', response)
  }
  return success
}
