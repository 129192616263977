import React from 'react'
import { node, string, bool } from 'prop-types'
import styled from 'styled-components'
// Internal
import { InputGroup } from './Input'

export const SubmitInput = styled.input`
  padding: 0.5rem 1rem;
`

export function Submit(props) {
  return (
    <InputGroup className={props.rowClassName}>
      <SubmitInput type="submit" disabled={props.disabled} className={props.className} value={props.value} />
      {props.children}
    </InputGroup>
  )
}

Submit.displayName = 'SubmitInput'

Submit.defaultProps = {
  disabled: false,
}

Submit.propTypes = {
  children: node,
  className: string,
  disabled: bool,
  required: bool,
  value: string.isRequired,
  rowClassName: string,
}
