import React from 'react'
import { array, bool, string, func, any } from 'prop-types'
// Internal
import { Input } from './Input'

export function Password(props) {
  return <Input type="password" {...props} />
}

Password.displayName = 'PasswordInput'

Password.defaultProps = {
  name: 'password',
  label: 'Password',
  placeholder: '******************',
  autoComplete: 'current-password',
}

Password.propTypes = {
  id: string,
  label: string,
  placeholder: string,
  className: string,
  errorClassName: string,
  labelClassName: string,
  rowClassName: string,
  autoComplete: string,
  name: string,
  required: bool,
  disabled: bool,
  errors: array.isRequired,
  value: any.isRequired,
  onChange: func.isRequired,
}
