import React from 'react'
import { oneOf, oneOfType, any, array, bool, func, string } from 'prop-types'
// Internal
import { getErrors } from '../DisplayErrors'
import { InputGroup, Label } from './Input'

export function Textarea(props) {
  const errors = getErrors(props)
  let className = props.className
  if (errors.length !== 0 && props.errorClassName) {
    className = typeof className === 'string' ? `${className} ${props.errorClassName}` : props.errorClassName
  }
  return (
    <InputGroup className={props.rowClassName}>
      <Label className={props.labelClassName} htmlFor={props.id || props.name}>
        {props.label}
      </Label>
      <textarea
        id={props.id || props.name}
        name={props.name}
        disabled={props.disabled}
        className={className}
        required={props.required}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        onChange={props.onChange}
        value={props.value}
        cols="30"
        rows="7"
      />
    </InputGroup>
  )
}

Textarea.displayName = 'Textarea'

Textarea.propTypes = {
  id: string,
  required: bool,
  disabled: bool,
  label: string.isRequired,
  placeholder: string,
  className: string,
  errorClassName: string,
  labelClassName: string,
  rowClassName: string,
  name: string.isRequired,
  errors: array.isRequired,
  value: any.isRequired,
  autoComplete: string,
  autoFocus: bool,
  spellcheck: oneOfType([bool, oneOf(['default'])]),
  onChange: func.isRequired,
}
