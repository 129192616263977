import React, { useContext } from 'react'
import { createOnSubmit, useFormChange } from 'react-endpoint'
import { api } from '@whirlwindjs/client'
// Internal
import { actions, Context } from '../../context'
import { Email, ErrorMessage, PageLink, Password, Submit } from '../../elements'
import { Page } from '../../layouts'

export function Login() {
  const { helpers } = useContext(Context)
  const initialState = {
    email: '',
    password: '',
    errors: [],
    isLoading: false,
    message: '',
  }
  const [onChange, values, setValues] = useFormChange(initialState)
  const onSubmit = createOnSubmit({
    makeRequest: async (inputs) => api.auth.login(inputs),
    useState: [values, setValues],
    onSuccess: async ({ user }) => {
      if (!user) {
        return setValues({
          ...values,
          message: 'Something went wrong. Please try again.',
        })
      }
      setValues(initialState)
      helpers.flash('Successfully logged in.')
      helpers.setUser(user)
      helpers.dispatchAction(actions.SHOW_AUTHENTICATE_MODAL, false)
    },
  })
  return (
    <Page>
      <form onSubmit={onSubmit}>
        <h1>Login</h1>
        {values.isLoading && 'Loading.... Please wait'}
        <ErrorMessage message={values.message} />
        <Email value={values.email} autoFocus={true} onChange={onChange} errors={values.errors} />
        <Password value={values.password} onChange={onChange} errors={values.errors} />
        <Submit value="Login" disabled={values.isLoading}>
          <p>
            <PageLink to="/password/forgot" title="Forgot Your Password?" />
          </p>
        </Submit>
      </form>
    </Page>
  )
}

Login.displayName = 'LoginPage'
